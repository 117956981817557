import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: '1.5px solid',
    borderColor: theme.palette.grey[800],
    outline: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  button: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    minWidth: 180,
  },
  smallbutton: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    minWidth: 100,
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  smallicon: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
  },
  helpText: {
    overflowWrap: 'break-word',
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.fontSize,
  },
});

export const useButtonClasses = makeStyles({
  root: {
    display: 'block',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
});

export const useToolTipClasses = makeStyles({
  tooltip: { margin: theme.spacing(1) },
});
