import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  EmailShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  // WeiboShareButton,
} from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane, faEnvelope } from '@fortawesome/pro-light-svg-icons';

import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Typography,
  FormHelperText,
  Tooltip,
  Hidden,
  Button,
} from '@material-ui/core';

import useStyles, { useButtonClasses, useToolTipClasses } from './styles';

const SendLoyaltyCardModal = ({
  name, url, open, action,
}) => {
  const styles = useStyles();
  const buttonClasses = useButtonClasses();
  const toolTipClasses = useToolTipClasses();

  return (
    <Modal
       aria-labelledby="transition-modal-title"
       aria-describedby="transition-modal-description"
       className={styles.modal}
       open={open}
       onClose={action}
       closeAfterTransition
       BackdropComponent={Backdrop}
       BackdropProps={{ timeout: 500 }}
     >
       <Fade in={open}>
         <div className={styles.paper}>
           <Grid spacing={2}
               container
               direction="row"
               justifyContent="center"
               alignItems="stretch"
             >
             <Grid item xs={10}>
               <Typography variant="h5" component="p" className={styles.heading}>
                 {`Share ${name}`}
               </Typography>
             </Grid>
             <Grid item xs={5}>
              <WhatsappShareButton
                url={url}
                title={`Download a ${name} Loyalty Card\n\n`}>
                <Hidden xsDown>
                  <Button
                   component="p"
                   classes={buttonClasses}
                   variant="outlined"
                   color="secondary"
                   className={styles.button}
                   aria-label="Send by WhatsApp"
                    >
                    <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} size="lg"/>
                    WhatsApp
                 </Button>
              </Hidden>
              <Hidden smUp>
                <Tooltip
                  arrow
                  title="WhatsApp"
                  aria-label="Send by WhatsApp"
                  placement="top"
                  classes={toolTipClasses}>
                    <Button
                    component="p"
                     classes={buttonClasses}
                     variant="outlined"
                     color="secondary"
                     className={styles.smallbutton}
                     aria-label="Send by WhatsApp"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} className={styles.smallicon} size="2x"/>
                     </Button>
                </Tooltip>
              </Hidden>
              </WhatsappShareButton>
             </Grid>
             <Grid item xs={5}>
              <FacebookMessengerShareButton
                url={url}
                appId="378480403182368">
                <Hidden xsDown>
                  <Button
                  component="p"
                   classes={buttonClasses}
                   variant="outlined"
                   color="secondary"
                   className={styles.button}
                   aria-label="Send by facebook messenger"
                    >
                    <FontAwesomeIcon icon={faFacebookMessenger} className={styles.icon} size="lg"/>
                    Messenger
                 </Button>
              </Hidden>
              <Hidden smUp>
                <Tooltip
                  arrow
                  title="Facebook Messenger"
                  aria-label="Send by Facebook Messenger"
                  placement="top"
                  classes={toolTipClasses}>
                    <Button
                    component="p"
                     classes={buttonClasses}
                     variant="outlined"
                     color="secondary"
                     className={styles.smallbutton}
                     aria-label="Send by Facebook Messenger"
                      >
                      <FontAwesomeIcon icon={faFacebookMessenger} className={styles.smallicon} size="2x"/>
                   </Button>
                </Tooltip>
              </Hidden>
              </FacebookMessengerShareButton>
             </Grid>
             <Grid item xs={5}>
              <EmailShareButton
                url={url}
                subject={`Download a ${name} Loyalty Card`}
                body={`Use the link below to download a "${name}" Loyalty Card\n\n`}>
                <Hidden xsDown>
                  <Button
                  component="p"
                   classes={buttonClasses}
                   variant="outlined"
                   color="secondary"
                   className={styles.button}
                   aria-label="Send by email"
                    >
                    <FontAwesomeIcon icon={faEnvelope} className={styles.icon} size="lg"/>
                    Email
                 </Button>
              </Hidden>
              <Hidden smUp>
                <Tooltip
                  arrow
                  title="Email"
                  aria-label="Send by email"
                  placement="bottom"
                  classes={toolTipClasses}>
                    <Button
                     component="p"
                     classes={buttonClasses}
                     variant="outlined"
                     color="secondary"
                     className={styles.smallbutton}
                     aria-label="Send by email"
                      >
                      <FontAwesomeIcon icon={faEnvelope} className={styles.smallicon} size="2x"/>
                   </Button>
                </Tooltip>
              </Hidden>
              </EmailShareButton>
             </Grid>
             <Grid item xs={5}>
              <TelegramShareButton
                url={`Download a ${name} Loyalty Card\n\n ${url}`}>
                <Hidden xsDown>
                  <Button
                   component="p"
                   classes={buttonClasses}
                   variant="outlined"
                   color="secondary"
                   className={styles.button}
                   aria-label="Send by Telegram"
                    >
                    <FontAwesomeIcon icon={faPaperPlane} className={styles.icon} size="lg"/>
                    Telegram
                 </Button>
              </Hidden>
              <Hidden smUp>
                <Tooltip
                  arrow
                  title="Telegram"
                  aria-label="Send by Telegram"
                  placement="bottom"
                  classes={toolTipClasses}>
                    <Button
                     component="p"
                     classes={buttonClasses}
                     variant="outlined"
                     color="secondary"
                     className={styles.smallbutton}
                     aria-label="Send by Telegram"
                      >
                      <FontAwesomeIcon icon={faPaperPlane} className={styles.smallicon} size="2x"/>
                   </Button>
                </Tooltip>
              </Hidden>
              </TelegramShareButton>
             </Grid>
             <Grid item xs={10}>
               <FormHelperText className={styles.helpText}>
                 Send a link to your loyalty card
               </FormHelperText>
             </Grid>
          </Grid>
         </div>
       </Fade>
     </Modal>
  );
};

SendLoyaltyCardModal.propTypes = {
  name: string.isRequired,
  url: string.isRequired,
  open: bool.isRequired,
  action: func.isRequired,
};

export default SendLoyaltyCardModal;
